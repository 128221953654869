<template>
  <!--Program-->
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="box p-4">
            <h2 class="title text-center mb-4">Bilimsel Program</h2>
            <div class="stage-picker mb-4">
              <b-button
                size="lg"
                variant="outline-primary"
                @click="pickStage('tkO3hj9I4VCwNRfo2vVu')"
                >12 Şubat 2021 CUMA</b-button
              >
              <b-button
                size="lg"
                variant="outline-primary"
                @click="pickStage('eeOOHqIv6shjjcwMij7G')"
                >13 Şubat 2021 CUMARTESİ</b-button
              >
            </div>
            <ul class="program">
              <!--Item-->
              <li
                class="d-flex p-3"
                v-for="session in sessionList"
                :key="session.id"
              >
                <div class="session-time">
                  <img class="time" src="@/assets/images/time.svg" alt="" />
                  {{ session.start | moment("HH:mm") }} -
                  {{ session.end | moment("HH:mm") }}
                  <b-badge
                    variant="danger"
                    v-if="session.isLive"
                    style="
                      color: #fff;
                      display: block;
                      padding: 0.4rem;
                      width: 40px;
                    "
                    >Live</b-badge
                  >
                </div>
                <div>
                  <div class="title">{{ session.title }}</div>

                  <span
                    class="description"
                    v-if="session.description"
                    v-html="session.description"
                  ></span>
                </div>
                <span class="sponsor" v-if="session.sponsorLogo">
                  <img :src="session.sponsorLogo" />
                </span>
              </li>

              <!--Item
              <li class="d-flex mb-3">
                <div>09:00 - 10:00</div>
                <div>
                  <p>Açılış Konuşması</p>
                  <span>Dr. Barış Ökçün</span>
                  <small
                    >İstanbul Üniversitesi Cerrahpaşa Kardiyoloji Enstitüsü -
                    İstanbul</small
                  >
                </div>
              </li>
              -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'StageList',
  components: {},
  data() {
    return {
      selectedStage: 'tkO3hj9I4VCwNRfo2vVu',
      isReady: false,
    };
  },
  computed: {
    ...mapGetters({
      sessionList: 'agenda/list',
      loading: 'agenda/loading',
      schema: 'agenda/schema',
      currentEventPath: 'event/currentPath',
    }),
    sessionPath() {
      return `${this.currentEventPath}/stage/${this.selectedStage}/session`;
    },
  },
  methods: {
    ...mapActions({
      setCurrent: 'agenda/setCurrent',
      findAgenda: 'agenda/find',
      clearState: 'agenda/clearItems',
    }),
    getAgenda() {
      this.clearState();
      return this.findAgenda({
        path: this.sessionPath,
        subscribe: true,
        order: { field: 'start', desc: false },
      }).then(() => {});
    },
    pickStage(stageId) {
      this.selectedStage = stageId;
      this.getAgenda();
    },
  },
  created() {
    this.getAgenda().then(() => {
      this.isReady = true;
    });
  },
};
</script>

<style lang="scss">
.session-time {
  font-weight: bold;
  font-size: 14px;
  padding-right: 3rem;
  border-right: 2px solid #b60016;

  img.time {
    width: 16px;
    opacity: 0.3;
    margin-top: -3px;
  }
}

.stage-picker {
  display: flex;
  justify-content: center;
  margin: auto;
  button {
    font-size: 12px;
    font-weight: bold;
    margin-left: 4px;
  }
}

.sponsor {
  width: 130px;
  position: absolute;
  right: 64px;
  img {
    object-fit: contain;
  }
}
</style>
