<template>
  <!--Header Banner-->
  <section class="header-banner">
    <img src="@/assets/images/rader_slider-1600x500-01.png" />
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>
